.Button {
  border: 0;
  cursor: pointer;
  color: white;
  width: 50px;
}

.Button.Primary {
  background-color: var(--primary-color);
}

.Button.Secondary {
  background-color: var(--light-gray);
}

.Button:hover {
  background-color: var(--primary-color-light);
}
