.LikeDislike {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.Like,
.Dislike {
  transition: 0.2s;
  border: 0;
  background-color: transparent;
  color: var(--gray);
}

.Like:not([disabled]),
.Dislike:not([disabled]) {
  cursor: pointer;
}

.Like.Active,
.Like:hover:not([disabled]) {
  color: #de68d5;
}

.Dislike.Active,
.Dislike:hover:not([disabled]) {
  color: #5c52e2;
}
