.Player {
  border-top: 1px solid #454545;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 15px;
  background-color: var(--gray);
  height: var(--player-height);
  min-height: var(--player-height);
  position: absolute;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  transition: height 0.4s, background 0.4s;
}

.Player.Open {
  transition: height 0.4s, background 1.2s;
  height: var(--opened-player-height);
  background-color: black;
}

.TopPlayer {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.TogglePlayer {
  background-color: transparent;
  border: 0;
  color: var(--lighter-gray);
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.TogglePlayer:hover {
  color: var(--white);
}

.Title {
  margin: 0;
  color: var(--lighter-gray);
  font-size: 1.3rem;
}

.Artist {
  margin: 0;
  font-weight: 400;
  color: var(--lighter-gray);
  font-size: 1rem;
}

.PlayButton {
  margin-right: 7px;
  width: 3rem;
  height: 3rem;
}

.SeekBar {
  width: 100%;
}
