.Search {
  display: flex;
  height: 100%;
  align-items: stretch;
  background-color: black;
}

.Search:hover {
  background-color: var(--gray);
}

.Input {
  margin: 0 0.2rem;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  flex-grow: 1;
  color: white;
}

.Button {
  border: 0;
  background-color: transparent;
  width: 2rem;
  color: var(--lighter-gray);
  cursor: pointer;
}

.Button:hover {
  color: white;
}
