:root {
  --seekbar-height: 4px;
  --knob-size: calc(var(--seekbar-height) * 2);
}

.wrapper {
  align-items: center;
  display: flex;
  color: var(--lighter-gray);
  font-weight: 400;
}

.seekBar {
  position: relative;
  flex-grow: 1;
  cursor: pointer;
  user-select: none;
  background-color: var(--lighter-gray);
  border-radius: 25px;
  margin: 0 15px;
  height: var(--seekbar-height);
}

.fill {
  background-color: var(--primary-color-light);
  border-radius: 25px;

  height: var(--seekbar-height);
}

.knob {
  position: absolute;
  display: none;

  background-color: var(--white);
  border-radius: calc(var(--knob-size) / 2);
  margin-top: calc(var(--knob-size) * -0.75);
  margin-left: calc(var(--knob-size) / -2);
  height: var(--knob-size);
  width: var(--knob-size);
}

.seekBar:hover > .fill {
  background-color: var(--primary-color) !important;
}

.seekBar:hover > .knob {
  display: block !important;
}
