.PlayButton {
  transition: color 0.3s, border-color 0.3s;
  background-color: transparent;
  color: var(--lighter-gray);
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PlayButton:hover {
  color: var(--primary-color-light);
}

.PlayButton:hover > .Pause::before,
.PlayButton:hover > .Pause::after {
  background-color: var(--primary-color-light);
  animation: none;
}

.PlayButton:active {
  color: var(--primary-color);
}

.PlayButton:active > .Pause::before,
.PlayButton:active > .Pause::after {
  background-color: var(--primary-color);
}

.Pause {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0;
  width: 1rem;
  height: 1rem;
}

.Pause::before,
.Pause::after {
  content: "";
  width: 7px;
  height: 100%;
  background-color: var(--lighter-gray);
  border-radius: 2px;
}

.Pause::before {
  animation: Bar1Anim 1s 0s infinite;
  animation-timing-function: linear;
}

.Pause::after {
  animation: Bar2Anim 0.8s 0s infinite;
  animation-timing-function: linear;
}

@keyframes Bar1Anim {
  0% {
    height: 65%;
  }
  15% {
    height: 10%;
  }
  50% {
    height: 25%;
  }
  60% {
    height: 15%;
  }
  75% {
    height: 100%;
  }
  90% {
    height: 60%;
  }
  100% {
    height: 65%;
  }
}

@keyframes Bar2Anim {
  0% {
    height: 50%;
  }
  40% {
    height: 10%;
  }
  50% {
    height: 30%;
  }
  65% {
    height: 60%;
  }
  85% {
    height: 100%;
  }
  100% {
    height: 50%;
  }
}
