:root {
  --modal-padding: 18px;
}

.Dimmer {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 99;
}

.Modal {
  width: 80vw;
  max-width: 500px;
  position: relative;
  background: rgb(57, 57, 57);
  background: linear-gradient(
    0deg,
    rgba(57, 57, 57, 1) 75%,
    rgba(74, 74, 74, 1) 100%
  );
  padding: var(--modal-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #454545;
  border-radius: 5px;
}

.Title {
  margin: 0;
  font-size: 1.1rem;
  color: var(--lighter-gray);
}

.Content {
  margin: 20px 0;
  font-size: 0.9rem;
  line-height: 1.4rem;
  color: var(--light-gray);
  font-weight: 400 !important;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.Image {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.ButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.SubmitButton,
.CancelButton {
  border: 0;
  background-color: transparent;
  font-size: 1.1rem;
  cursor: pointer;
}

.SubmitButton:hover {
  color: var(--primary-color-light);
}

.CancelButton:hover {
  color: var(--white);
}

.SubmitButton {
  color: var(--primary-color);
}

.CancelButton {
  color: var(--lighter-gray);
}

.CloseButton {
  position: absolute;
  right: var(--modal-padding);
  top: var(--modal-padding);
  background-color: transparent;
  border: 0;
  color: var(--lighter-gray);
}

.CloseButton:hover {
  color: var(--white);
  cursor: pointer;
}
