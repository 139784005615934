:root {
  --background-color: #222;
  --white: #eee;
  --lighter-gray: #ccc;
  --light-gray: #888;
  --gray: #333;
  --primary-color: #308aca;
  --primary-color-light: #57c0ee;
  --primary-color-darker: #002138;

  --player-height: 95px;
  --content-height: calc(100% - var(--player-height));
  --navbar-height: 50px;
  --opened-player-height: calc(100% - var(--navbar-height));
}

* {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

html {
  background-color: var(--background-color);
}

body {
  margin: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 60%,
    rgba(0, 23, 40, 1) 100%
  );
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.scrollable::-webkit-scrollbar-track {
  background: none;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollable::-webkit-scrollbar-thumb {
  background: var(--light-gray);
  border-radius: 25px;
}

/* latin 400 */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/roboto.400.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
