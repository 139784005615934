.Comment {
  flex-grow: 1;
  resize: none;
  font-size: 1rem;
  padding: 15px;
  font-weight: 400;
  color: var(--lighter-gray);
  background-color: #252525;
  border: solid 1px transparent;
  height: 128px;
}

.Comment:focus {
  border: solid 1px var(--primary-color);
}
