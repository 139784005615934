.Table {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
}

.Row.Header {
  border-bottom: solid 2px var(--gray);
  height: 3rem;
  align-items: flex-end;
  color: var(--white);
}

.Row {
  border-bottom: 1px solid var(--gray);
  display: flex;
  align-items: stretch;
  padding: 0.4rem 1rem;
  box-sizing: border-box;
  height: 100%;
}

.Row:hover {
  background-color: var(--primary-color-darker);
}

.Header.Row:hover {
  background-color: transparent;
}

.Cell {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.Row > .Cell {
  font-weight: 400;
  color: var(--white);
  align-items: center;
}

.Header.Row > .Cell {
  font-weight: 400;
  align-items: flex-end;
}

.HeaderCell {
  cursor: pointer;
  user-select: none;
}

.HeaderCell:hover,
.HeaderCell.Selected {
  color: var(--primary-color);
}

.OrderedHeaderCellContent {
  margin-right: 5px;
}

.Cell:last-of-type {
  justify-content: flex-end;
}

.Body {
  flex: 1;
}

.Button {
  border: 0;
  padding: 0;
  background: transparent;
  color: var(--primary-color-light);
  cursor: pointer;
}

.Button:hover {
  color: var(--primary-color);
}

.ResizingText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--lighter-gray);
}
