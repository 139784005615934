:root {
  --max-width: 300px;
  --button-size: 70px;
}

.PlayerView {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  width: 350px;
  box-sizing: border-box;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-height: 550px;
  width: var(--max-width);
  height: 100%;
}

.Cover {
  height: 250px;
  width: 250px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--gray);
}

.LikeContainer {
  margin: 15px 0;
  width: 100%;
}

@media screen and (max-height: 580px) {
  .Cover {
    display: none;
  }
}

.Title {
  margin: 0;
  color: var(--lighter-gray);
  text-align: center;
  /* multiline text: truncate, with ellipsis */
  --max-lines: 2;
  --line-height: 2.3rem;
  line-height: var(--line-height);
  height: calc(var(--line-height) * var(--max-lines));
  display: -webkit-box;
  -webkit-line-clamp: var(--max-lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Artist {
  margin: 0;
  font-weight: 400;
  text-align: center;
  font-size: 1.5rem;
  color: var(--light-gray);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(var(--max-width) - 1rem);
}

.PlayButton,
.BackwardButton,
.ForwardButton {
  width: var(--button-size);
  height: var(--button-size);
}

.PlayButton {
  border-radius: calc(var(--button-size) / 2);
  border: solid var(--lighter-gray) 3px !important;
}

.PlayButton:hover {
  border-color: var(--primary-color-light) !important;
}

.PlayButton:active {
  border-color: var(--primary-color) !important;
}

.BackwardButton,
.ForwardButton {
  transition: 0.2s;
  color: var(--lighter-gray);
  background-color: transparent;
  border: 0;
}

.BackwardButton:hover,
.ForwardButton:hover {
  color: var(--primary-color-light);
}

.BackwardButton:active,
.ForwardButton:active {
  color: var(--primary-color);
}

.PlayerControlsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px 0;
}

.SeekBarContainer {
  width: 100%;
}
