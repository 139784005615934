.Header {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--gray);
  background-color: #002138;
}

.HeaderTop {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  min-height: var(--navbar-height);
}

.HeaderBottom {
  height: 40px;
}

.HeaderLeft {
  display: flex;
  align-items: center;
}

.HeaderRight {
  display: flex;
}

.Title {
  color: var(--white);
  margin: 0;
  font-size: 1.3rem;
  font-weight: 500;
}

.SearchBarContainer {
  width: 255px;
}

.SearchBarContainerXL {
  margin-top: 15px;
}

.Logo {
  margin: 0 10px;
}

.Info {
  color: white;
  padding: 5px;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  user-select: none;
}

.Info.Success {
  background-color: #10c210;
}

.Info.Warning {
  background-color: #e06b09;
}
