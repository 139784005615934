.Content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  height: var(--content-height);
}

.Content.FullHeight {
  height: 100%;
}

.ContentContainer {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.LikeDislikeTableCell {
  width: 5rem;
}

.Active.PlayButton {
  color: var(--primary-color-light);
}

.Active.PlayButton > div::after,
.Active.PlayButton > div::before {
  background-color: var(--primary-color-light);
}

.Active.PlayButton:hover {
  color: var(--primary-color);
}

.Active.PlayButton:hover > div::after,
.Active.PlayButton:hover > div::before {
  background-color: var(--primary-color);
}

.PlayButtonColumn {
  max-width: 35px;
}

.TrackColumn {
  flex-grow: 2 !important;
}

.ReleaseYearColumn {
  padding-left:50px;
}



.Track {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.TrackTitle,
.TrackArtist {
  font-size: 1rem;
  margin: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.TrackTitle {
  color: var(--lighter-gray);
}

.TrackArtist {
  color: var(--light-gray);
  font-weight: 400;
}

.ShowSongWithoutApproval {
  animation-name: blink;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    background-color: var(--primary-color-darker);
  }
  50% {
    background-color: #00213888;
  }
  100% {
    background-color: var(--primary-color-darker);
  }
}
